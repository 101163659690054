$indicator-text-height: 40px;

$indicator-text-overlap: $indicator-text-height * 0.3;

$bar-height: 60px;
$bar-top: $indicator-text-height - $indicator-text-overlap;

$indicator-height: $bar-height - $indicator-text-overlap;
$indicator-top: $indicator-text-height;

$range-label-margin: 5px;
$range-label-top: $bar-top + $bar-height + $range-label-margin;
$range-label-height: 25px;

$total-height: $indicator-text-height + $bar-height - $indicator-text-overlap + $range-label-margin +
  $range-label-height;

.color-progress-bar {
  display: block;
  margin: 20px;
  box-sizing: border-box;
  position: relative;
  height: $total-height;

  .bar {
    position: absolute;
    z-index: 100;
    top: $bar-top;
    height: $bar-height;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
  }
  .indicator-text {
    position: absolute;
    z-index: 101;
    top: 0;
    height: $indicator-text-height;
    line-height: $indicator-text-height;
    background-color: #888888;
    color: white;
    font-size: 1em;
    font-weight: bold;
    border-radius: 100px;
    text-align: center;
  }
  .indicator {
    position: absolute;
    z-index: 101;
    top: $indicator-top;
    height: $indicator-height;
    background-color: #888888;
  }
  .range {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 0;
    height: $bar-height;
    color: #333;
    &:hover {
      border: 1px solid #888;
      .range-name {
        font-weight: bold;
      }
      .range-desc {
        display: block;
      }
    }
  }
  .range-name {
    overflow: hidden;
    width: 100%;
    line-height: 1.2em;
  }
  .range-desc {
    position: absolute;
    z-index: 104;
    display: none;
    width: auto;
    min-width: 200px;
    top: $bar-height + 5px + $range-label-height;
    left: auto;
    white-space: normal;
    word-wrap: break-word;
    background-color: rgb(179 236 250 / 90%);
    border-radius: 10px;
    padding: 15px;
    border: 1px solid #8fb6bf;
    box-shadow: 3px 2px 6px 1px rgba(128, 128, 128, 0.3);
  }
  .range-label {
    top: $range-label-top;
    height: $range-label-height;
    line-height: $range-label-height;
    text-align: center;
    position: absolute;
    z-index: 100;
  }
}
