.address-bar {
  margin-top: 20px;
}

.address-line {
  display: flex;
  flex-direction: row;
}
.address-dropdown {
  flex-grow: 1;
}
.address-add-button {
  line-height: 45px;
  height: 49px;
  width: 49px;
  text-align: center;
  font-size: 2em;
  color: #272727;
  margin-left: 10px;
  background: #e4e4e4;
  border-radius: 4px;
  box-shadow: none;
  &:hover {
    cursor: pointer;
    background-color: #c2c2c2;
  }
}

.add-address {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgb(0 0 0 / 50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-address-panel {
  position: absolute;
  z-index: 1001;
  width: 60%;
  max-width: 900px;
  min-width: 600px;
  padding: 30px;
  border-radius: 10px;
  margin: 0 auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.add-address-close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 1.5em;
  &:hover {
    cursor: pointer;
  }
}
.add-address-text,
.add-address-form,
.add-address-metamask {
  margin-top: 15px;
}
.add-address-title {
  font-size: 1.5em;
}
.add-address-text {
  font-size: 1.1em;
  color: #666;
}
.add-address-form {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;
}
.add-address-input {
  flex-grow: 1;
  margin-right: 15px;
}
.add-address-button {
  min-width: 100px;
  padding: 0;
}
.add-address-metamask {
  max-width: 200px;
  align-self: center;
  cursor: pointer;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  &:hover {
    background-color: #f8f8f8;
  }
}
