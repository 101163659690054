@use 'sass:math';
@import "./resources/common.scss";
// @import "~bootstrap/scss/bootstrap";

* {
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  //   "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: #f8f8f8 !important;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input {
  padding: 15px;
  outline: none;
  background-color: white;
  border-color: #d6dbe4;
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus-visible {
    // border-color: $theme-color2;
    border-color: #96b7ff;
  }
}

button {
  display: inline-block;
  border: none;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  color: #fff;
  // background-color: $theme-color2;
  background-color: #4c93fe;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    // background-color: #a9c9d0;;
    background-color: #aec0dc;
  }
}

table {
  width: 100%;
  table-layout: fixed;
}
thead {
  background-color: #f0f0f0;
}
th,
td {
  padding: 5px 0;
  text-align: center;
}

$header-height: 60px;
$logo-size: 40px;
$icon-size: 30px;

.app-header {
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: $header-height;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  background-color: white;

  .logo,
  .icon {
    display: inline-block;
    cursor: pointer;
  }

  .logo {
    width: $logo-size;
    height: $logo-size;
  }
  .icon-container {
    height: $icon-size;
  }
  .icon {
    width: $icon-size;
    height: $icon-size;
  }
  .icon-monochrome {
    padding: 2px;
    fill: $theme-color4;
    &:hover {
      fill: $theme-color2;
    }
  }
}
.app-header-container {
  width: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  &.right {
    justify-content: flex-end;
  }
  > * {
    margin: 0 5px;
  }
}

.app-body {
  padding-top: $header-height + 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.defi-info {
  > * {
    margin-bottom: 20px;
  }
}

$defi-title-size: 30px;
$defi-title-info-size: 15px;
.defi-title {
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.defi-title-logo {
  height: $defi-title-size;
  width: $defi-title-size;
  margin-right: 15px;
}
.defi-title-text {
  font-size: $defi-title-size;
  color: #333;
}
.defi-title-tag {
  display: inline-block;
  text-decoration: none;
  color: $theme-color4;
  padding: 1px 10px;
  border: 1px solid $theme-color4;
  margin-left: 15px;
  font-size: 13px;
  cursor: pointer;
  border-radius: 100px;
  &:hover {
    color: $theme-color4;
    text-decoration: none;
  }
}
.defi-title-info-container {
  position: relative;
  cursor: pointer;
  height: $defi-title-size;
  margin-left: 10px;
  padding-left: 5px;
  padding-right: 20px;
  &:hover {
    > .defi-title-info-card {
      display: block;
    }
  }
}
.defi-title-info-icon {
  &:before {
    content: "i";
  }
  display: inline-block;
  text-align: center;
  line-height: $defi-title-info-size;
  width: $defi-title-info-size;
  height: $defi-title-info-size;
  font-size: $defi-title-info-size * 0.7;
  border: 1px solid #888;
  color: #888;
  font-weight: bold;
  border-radius: 50%;
}
.defi-title-info-card {
  &:hover {
    display: block;
  }
  position: absolute;
  z-index: 500;
  left: $defi-title-info-size + 20px;
  top: 0;
  display: none;
  width: 300px;
  max-width: 50vw;
  background-color: rgb(179 236 250 / 90%);
  border-radius: 10px;
  padding: 15px;
  border: 1px solid #8fb6bf;
  box-shadow: 3px 2px 6px 1px rgba(128, 128, 128, 0.3);
  p {
    margin: 0;
    font-size: 0.9em;
  }
  p + p {
    margin-top: 15px;
  }
}

// a card group which display cards using flex
// card cols and gutter can be passed in as parameters
@mixin card-group($cols-desktop, $cols-mobile, $gutter-x, $gutter-y) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -$gutter-x * 0.5;
  margin-right: -$gutter-x * 0.5;
  > * {
    width: 0;
    flex-grow: 1;
    margin: ($gutter-y * 0.5) ($gutter-x * 0.5);
    flex-basis: calc(#{math.div(100, $cols-desktop)}% - #{$gutter-x});

    @media screen and (max-width: 720px) {
      flex-basis: calc(#{math.div(100, $cols-mobile)}% - #{$gutter-x});
    }
  }
}

.defi-card-group-2 {
  @include card-group(2, 1, 20px, 20px);
}

.defi-card-group-6 {
  @include card-group(3, 2, 20px, 20px);
}

.defi-card {
  border-radius: 10px;
  background-color: white;
  padding: 15px 20px;
  > * {
    margin-bottom: 20px;
  }
}

.defi-card-title {
  font-size: 1em;
  color: #666;
}

.defi-card-large-title {
  font-size: 1.2em;
  color: #333;
}

.defi-card-large-text {
  font-size: 1.4em;
  text-align: center;
}

.defi-large-card {
  border-radius: 20px;
  background-color: white;
  padding: 30px;

  p {
    margin: 0;
    font-size: 18px;
  }
  p + p {
    margin-top: 20px;
  }
}
.defi-header-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  > * {
    margin: 0 30px;
    font-size: 20px;
    color: $theme-color4;
    text-decoration: none;
    text-underline-offset: 4px;
    &:hover {
      text-decoration: underline;
      color: $theme-color4;
    }
  }
  > .active {
    text-decoration: underline;
  }
}
